<template>
  <div class="card">
    <b-col cols="12" class="px-2 pt-1">
      <div cols="12" class="d-flex justify-content-between">
        <h4 class="font-weight my-auto">{{ assignments.title }}</h4>
        <div class="d-flex align-items-center">
          <b-button
            v-if="assignments.status === 'New'"
            size="sm"
            variant="outline-primary"
            class="mr-1"
          >
            New
          </b-button>
          <b-button
            v-if="assignments.status === 'Archieved'"
            size="sm"
            variant="outline-secondary"
            class="mr-1"
          >
            Archived
          </b-button>
          <b-button
            v-if="assignments.status === 'Payment Due'"
            size="sm"
            variant="outline-warning"
            class="mr-1"
          >
            Client Payment Due
          </b-button>
          <b-button
            v-if="assignments.status === 'Complete'"
            size="sm"
            variant="outline-success"
            class="mr-1"
          >
            Completed
          </b-button>
          <b-button
            v-if="assignments.status === 'Active'"
            size="sm"
            variant="outline-info"
            class="mr-1"
          >
            Active
          </b-button>
          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="1.5x"
                class="cursor-pointer icon text-dark my-auto"
              />
            </template>

            <b-dropdown-item @click="handleChangeStatus('Complete')">
              Mark as Complete
            </b-dropdown-item>
            <!-- <b-dropdown-item @click="handleChangeStatus('Payment Due')">
              Send Billing details
            </b-dropdown-item> -->
            <b-dropdown-item @click="handleChangeStatus('Archieved')">
              Mark as Archived
            </b-dropdown-item>
            <b-dropdown-item @click="editAssignment()">
              Edit Assignment
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </b-col>
    <b-col cols="12" class="m-0">
      <hr />
    </b-col>
    <b-col cols="12" class="px-2 pb-1">
      <div class="d-lg-flex d-md-flex justify-content-between">
        <div class="d-lg-flex d-md-flex">
          <div class="mr-3" v-if="assignments.client">
            <h6>Company</h6>
            <h5 class="font-weight">{{ assignments.client.name }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.required_candidate">
            <h6>Quantity</h6>
            <h5 class="font-weight">{{ assignments.required_candidate }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.industry">
            <h6>Industry</h6>
            <h5 class="font-weight">{{ assignments.industry }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.function">
            <h6>Function</h6>
            <h5 class="font-weight">{{ assignments.function }}</h5>
          </div>
          <div class="mr-3" v-if="assignments.required_experience">
            <h6>Experience</h6>
            <h5 class="font-weight">
              {{ assignments.required_experience }} years
            </h5>
          </div>
          <!-- <div class="mr-3">
            <h6>Hourly</h6>
            <h5 class="font-weight">$400</h5>
          </div> -->
          <div class="mr-3">
            <h6>Location</h6>
            <h5 class="font-weight">{{ assignments.country }}</h5>
          </div>
        </div>
        <div>
          <b-button @click="handleSeeJob()" variant="outline-primary" size="sm">
            See Job
          </b-button>
        </div>
      </div>
    </b-col>
  </div>
</template>
<script>
import axios from "@/libs/axios"
import errorResponse from "@/libs/axiosError"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import { BButton, BCol, BDropdown, BDropdownItem } from "bootstrap-vue"

export default {
  components: {
    BButton,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  data: () => ({}),
  props: {
    assignments: {
      type: [Object, Array],
      required: true,
    },
  },
  mounted() {},
  methods: {
    editAssignment() {
      this.$router.push({
        path: `/edit-assignment/${this.$route.params.id}`,
      })
    },

    handleSeeJob() {
      this.$router.push({
        path: `/assignment-details/${this.$route.params.id}`,
      })
    },
    handleChangeStatus(data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Change Assignment Status.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .put(`change_astatus/${this.$route.params.id}`, { status: data })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Status`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Changed Assignment Status!`,
                  },
                })
                this.$emit("get-assignment-data-engagement")
              })
              .catch((error) => {
                console.log(error)
                errorResponse(error, this.$router)
              })
          }
        })
    },
  },
}
</script>
<style>
.font-weight {
  font-weight: 600;
}
</style>
