<template>
  <div class="row row_margin">
    <div class="col-lg-12 px-0">
      <b-card>
        <h6 class="font-weight mb-0">
          {{ items.length }} Result found for Sourced
        </h6>
      </b-card>
      <b-card no-body class="p-1">
        <b-row>
          <b-col
            lg="5"
            md="5"
            sm="4"
            class="pb-1 my-auto d-flex justify-content-start align-items-center"
          >
            <b-form-group class="d-flex my-auto">
              <label class="d-inline text-sm-left">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
            <div>
              <span class="executive">Executive</span>
              <b-form-checkbox
                v-model="isExpert"
                checked="false"
                name="check-button"
                switch
                inline
              >
                Expert
              </b-form-checkbox>
            </div>
          </b-col>

          <b-col
            lg="7"
            md="7"
            class="pb-1 d-flex justify-content-end align-items-center"
          >
            <b-form-group
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              responsive
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            >
              <template #cell(show_details)="row">
                <b-form-checkbox
                  v-model="row.detailsShowing"
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-label">{{
                    row.detailsShowing ? "Hide" : "Show"
                  }}</span>
                </b-form-checkbox>
              </template>
              <template #row-details="row">
                <b-card class="mb-0">
                  <div class="card__box">
                    <div class="mb-0 card__left">
                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.experiences &&
                          row.item.candidate.experiences.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Experience</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(experience, i) in row.item.candidate
                                  .experiences"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ experience.designation }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ experience.company }}
                                    </h6>
                                    <span class="font-11"
                                      >{{ experience.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{
                                        experience.presentely_working === "true"
                                          ? experience.to
                                          : "Present"
                                      }}</span
                                    >
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="12"
                        v-if="
                          row.item.candidate &&
                          row.item.candidate.educations &&
                          row.item.candidate.educations.length !== 0
                        "
                      >
                        <div class="row pb-2">
                          <div class="y-center col-lg-2 bor-1">
                            <h6>Qualification</h6>
                          </div>
                          <div class="col-lg-10">
                            <div>
                              <app-timeline
                                v-for="(qualification, i) in row.item.candidate
                                  .educations"
                                :key="i"
                              >
                                <app-timeline-item variant="primary">
                                  <div
                                    class="d-flex flex-sm-row flex-column mb-sm-0 mb-1"
                                  >
                                    <h6 class="mr-1 inline">
                                      {{ qualification.degree }}
                                    </h6>
                                    <span class="mr-1 font-11 inline">at</span>
                                    <h6 class="mr-1 inline">
                                      {{ qualification.institute_name }}
                                    </h6>
                                    <span class="font-11">
                                      {{ qualification.from }}
                                    </span>
                                    <span class="font-11 mx-1"> to </span>
                                    <span class="font-11">
                                      {{ qualification.to }}
                                    </span>
                                  </div>
                                </app-timeline-item>
                              </app-timeline>
                            </div>
                          </div>
                        </div>
                      </b-col>

                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.industries
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Industry</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(industry, i) in JSON.parse(
                                row.item.candidate.industries
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ industry.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Functions</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col
                        md="8"
                        class="pb-1"
                        v-if="
                          row.item.candidate && row.item.candidate.sub_functions
                        "
                      >
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Sub Function</h6>
                          </div>
                          <div class="col-lg-9">
                            <span
                              v-for="(functionName, i) in JSON.parse(
                                row.item.candidate.sub_functions
                              )"
                              :key="i"
                            >
                              <span>
                                <span v-if="i !== 0">|</span>
                                {{ functionName.name }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="8" class="pb-1">
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Phone Number</h6>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ row.item.user.phone }}</span>
                          </div>
                        </div>
                      </b-col>
                      <b-col md="8" class="pb-1">
                        <div class="row">
                          <div class="y-center col-lg-3 bor-1">
                            <h6>Email</h6>
                          </div>
                          <div class="col-lg-9">
                            <span>{{ row.item.user.email }}</span>
                          </div>
                        </div>
                      </b-col>
                    </div>

                    <div class="d-flex justify-content-between">
                      <div class="card__right mr-1">
                        <div v-if="row.item.status !== 'Candidate Declined'">
                          <b-dropdown
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            text="Change Status"
                            variant="primary"
                            right
                          >
                            <b-dropdown-item @click="showInviteModal(row.item)">
                              Interview
                            </b-dropdown-item>
                            <b-dropdown-item
                              @click="handleStatusChange('Archived', row.item)"
                            >
                              Archive
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                        <br />
                        <div class="mt-2">
                          <span> Last Updated By, </span>
                          <br />
                          <span
                            >{{
                              moment(row.item.updated_at).format(
                                "MMMM Do YYYY, h:mm:ss a"
                              )
                            }}
                          </span>
                          <p class="bold">
                            <span v-if="row.item.name">
                              {{ row.item.name }}
                            </span>
                            <span v-else>
                              {{ row.item.first_name }} {{ row.item.last_name }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-card>
              </template>
              <template #cell(status)="data">
                <b-badge
                  variant="light-secondary"
                  v-if="data.item.status === null"
                >
                  Null Status
                </b-badge>
                <b-badge
                  variant="light-success"
                  v-if="data.item.status === 'Candidate Accepted'"
                >
                  Candidate Accepted
                </b-badge>
                <b-badge
                  variant="light-success"
                  v-if="data.item.status === 'Client Accepted'"
                >
                  Client Accepted
                </b-badge>
                <b-badge
                  variant="light-success"
                  v-if="data.item.status === 'Candidate Invited'"
                >
                  Candidate Invited
                </b-badge>
                <b-badge
                  variant="light-danger"
                  v-if="data.item.status === 'Candidate Declined'"
                >
                  Candidate Declined
                </b-badge>
                <b-badge
                  variant="light-warning"
                  v-if="data.item.status === 'Shared with Client'"
                >
                  Shared with Client
                </b-badge>
                <b-badge
                  variant="light-danger"
                  v-if="data.item.status === 'Client Declined'"
                >
                  Candidate Declined
                </b-badge>
              </template>
              <template #cell(questions)="data">
                <feather-icon
                  @click="questionModal(data.item)"
                  icon="EyeIcon"
                  size="16"
                  class="cursor-pointer icon text-dark ml-2"
                />
              </template>
              <template #cell(actions)="data">
                <div class="d-flex align-items-center">
                  <feather-icon
                    icon="MessageSquareIcon"
                    size="17"
                    class="cursor-pointer d-sm-block d-none mr-1"
                    @click="routeToChat(data.item)"
                  />
                  <div
                    class="text-nowrap"
                    v-if="
                      data.item.status === 'Candidate Invited' ||
                      data.item.status === 'Candidate Accepted' ||
                      data.item.status === null
                    "
                  >
                    <!-- Dropdown -->
                    <b-dropdown variant="link" toggle-class="p-0" no-caret left>
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>
                      <b-dropdown-item v-if="data.item.status === null">
                        <span
                          class="align-middle ml-50"
                          @click="handleInvite(data.item)"
                          >Invite Candidate</span
                        >
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          data.item.status === 'Candidate Invited' ||
                          data.item.status === 'Candidate Accepted'
                        "
                      >
                        <span
                          class="align-middle ml-50"
                          @click="handleShare(data.item)"
                          >Share to Client</span
                        >
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </div>
    <b-modal ref="question-modal" centered title="Questions" size="lg" ok-only>
      <b-row class="">
        <b-col lg="12" md="10" sm="10" class="mx-auto">
          <div v-if="assignmentQuestionAnswers.length !== 0">
            <div class="px-lg-5">
              <h4>
                Questions of id:
                <span v-if="candidateInfo" class="text-primary ml-1">
                  {{ candidateInfo.id }}
                </span>
              </h4>
              <div
                class="mt-2"
                v-for="(question, index) in assignmentQuestionAnswers"
                :key="index"
              >
                <h5 class="font-bold">
                  {{ index + 1 }}. {{ question.question }}
                </h5>
                <p>
                  {{ question.answer }}
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="px-lg-5" v-if="assignmentQuestions.length !== 0">
              <h4>
                Questions of id:
                <span v-if="candidateInfo" class="text-primary ml-1">
                  {{ candidateInfo.id }}
                </span>
              </h4>
              <div
                class="mt-2"
                v-for="(question, index) in assignmentQuestions"
                :key="index"
              >
                <h5 class="font-bold">Question {{ index + 1 }}</h5>
                <p>
                  {{ question }}
                </p>
              </div>
            </div>
            <div class="px-lg-5" v-else>
              <h4 style="text-align: center">
                There is No Question Assigned for this assignment!
              </h4>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      ref="interview-modal"
      hide-footer
      ok-title="Accept"
      modal-class="modal-primary"
      centered
      size="lg"
      title="Invite Candidate For Interview"
    >
      <b-row class="">
        <b-col lg="10" md="10" sm="10" class="mx-auto">
          <div class="px-lg-2">
            <validation-observer ref="inviteCandidateForm">
              <b-form @submit.prevent="handleInterview">
                <b-form-group label="Interview Title" class="my-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Title"
                    rules="required"
                  >
                    <b-form-input
                      v-model="interview.title"
                      selected-variant="primary"
                      placeholder="Enter Interview Title"
                      :state="errors.length > 0 ? false : null"
                      class="w-sm-75"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Select Date" class="mb-1">
                  <validation-provider
                    #default="{ errors }"
                    name="Date"
                    rules="required"
                  >
                    <b-form-datepicker
                      v-model="interview.updateDate"
                      selected-variant="primary"
                      :state="errors.length > 0 ? false : null"
                      class="w-sm-75"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-row class="d-flex justify-content-between">
                  <b-col lg="6" md="6" sm="12">
                    <b-form-group label="Select Time" class="mb-1">
                      <validation-provider
                        #default="{ errors }"
                        name="Time"
                        rules="required"
                      >
                        <b-form-timepicker
                          v-model="interview.updateStartTime"
                          :state="errors.length > 0 ? false : null"
                          class="w-sm-75"
                          locale="en"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col lg="6" md="6" sm="12">
                    <b-form-group label="End Time" class="mb-1">
                      <b-form-timepicker
                        v-model="interview.updateEndTime"
                        class=""
                        locale="en"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-form-group label="Link" class="mb-1">
                  <b-form-input
                    v-model="interview.interviewLink"
                    class="w-sm-75"
                    placeholder="Enter meeting link"
                  />
                </b-form-group>
                <b-form-group label="Password" class="mb-1">
                  <b-form-input
                    v-model="interview.password"
                    class="w-sm-75"
                    type="password"
                    placeholder="Enter password"
                  />
                </b-form-group>
                <b-form-group label="Add Note" class="mb-1">
                  <b-form-textarea
                    v-model="interview.addNote"
                    placeholder="Write here..."
                    rows="4"
                  />
                </b-form-group>
                <div class="d-flex justify-content-center">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mx-auto d-inline-block my-3"
                    variant="primary"
                    type="submit"
                  >
                    Invite
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </b-col>
        <b-col lg="10" md="10" sm="10" class="mx-auto" />
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue"
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue"

import axios from "@/libs/axios"
import {
  BButton,
  BCard,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormTimepicker,
  BFormDatepicker,
  BFormTextarea,
  BForm,
} from "bootstrap-vue"
import Ripple from "vue-ripple-directive"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { required } from "@validations"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    BTable,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    AppTimeline,
    AppTimelineItem,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormTimepicker,
    BFormDatepicker,
    BFormTextarea,
    ValidationObserver,
    ValidationProvider,
    BForm,
  },
  directives: {
    Ripple,
  },
  props: {
    assignments: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      showExperience: 3,
      required,
      showQualification: 3,
      graduationStartYear: null,
      graduationEndYear: null,
      value2: null,
      graduation: [],
      dir: "ltr",
      trueValue: true,
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      isExpert: false,
      filter: null,
      filterTwo: "",
      filterOn: [],
      selectInstitute: [],
      selectedCompany: [],
      selectedCompanyType: null,
      selectedLocType: null,
      selectedIndustries: [],
      industries: [],
      selectedFunctions: [],
      functions: [],
      selectedSubFunctions: [],
      subFunctions: [],
      selectedSkills: [],
      location: "",
      searchName: "",
      skills: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        "show_details",
        { key: "user.name", label: "Full Name", sortable: true },
        { key: "candidate.title", label: "DESIGNATION", sortable: true },
        { key: "status", label: "Status", sortable: true },
        "questions",
        "actions",
      ],
      items: [],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
      interview: {
        updateDate: "",
        title: "",
        updateStartTime: "",
        updateEndTime: "",
        addNote: "",
        userName: "",
        interviewId: "",
        assignmentId: "",
        interviewLink: "",
        password: "",
      },
      assignmentQuestions: [],
      assignmentQuestionAnswers: [],
      candidateInfo: {},
    }
  },
  computed: {},
  watch: {
    isExpert(value) {
      if (!value) {
        this.fields = [
          "show_details",
          { key: "user.name", label: "Full Name", sortable: true },
          { key: "candidate.title", label: "DESIGNATION", sortable: true },
          { key: "status", label: "Status", sortable: true },
          "questions",
          "actions",
        ]
      } else {
        this.fields = [
          "show_details",
          { key: "user.name", label: "Full Name", sortable: true },
          { key: "candidate.title", label: "DESIGNATION", sortable: true },
          { key: "status", label: "Status", sortable: true },
          "questions",
          "actions",
        ]
      }
    },
  },
  mounted() {
    // Set the initial number of items
    this.getCandidates()
    this.$root.$on("bv::modal::hide", () => {
      this.assignmentQuestions = []
    })
  },
  created() {},
  updated() {},

  methods: {
    getSelected(option) {
      return (option && option.name) || ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    questionModal(candidate) {
      this.candidateInfo = candidate
      console.log(candidate.assignment.question)
      if (candidate.answers) {
        this.assignmentQuestionAnswers = JSON.parse(candidate.answers)
      } else if (candidate.assignment.question) {
        this.assignmentQuestionAnswers = []
        const data = candidate.assignment.question.split(",")
        data.forEach((element) => {
          this.assignmentQuestions.push(element)
        })
      }
      this.$refs["question-modal"].show()
    },
    async getCandidates() {
      axios
        .get(
          `/admin_candidate_assignment/${this.$route.params.id}?filter[status]=Shortlisted`
        )
        .then((res) => {
          this.items = res.data
          this.totalRows = this.items.length

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Candidate List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Candidate List!`,
            },
          })
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    handleStatusChange(status, data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Change Candidate Status.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            const sendingData = {
              status,
              assignment_id: data.assignment_id,
              candidate_id: data.candidate_id,
              id: data.id,
            }

            axios
              .post(`candidate_assignment/change_status`, sendingData)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Status`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Changed Assignment Status!`,
                  },
                })
                this.$emit("detailed-getAssignment")
                this.getCandidates()
              })
              .catch((error) => {
                console.log(error.response.status)
                errorResponse(error, this.$router)
              })
          }
        })
    },
    showInviteModal(data) {
      console.log(data)
      this.interview.userName = data.user.name
      this.interview.interviewId = data.interview_id
      this.interview.candidateId = data.candidate_id
      this.interview.assignmentId = data.assignment_id
      this.$refs["interview-modal"].show()
    },
    async handleInvite(data) {
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to Invite the Candidate for this Project.",
          {
            title: "Please Confirm",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .post("/invite_candidate", {
                candidate_id: data.candidate_id,
                assignment_id: data.assignment_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Invites`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Invited the candidate!`,
                  },
                })
                // this.interview.updateDate = ""
                // this.interview.updateStartTime = ""
                // this.interview.addNote = ""
                // this.interview.userName = ""
                // this.interview.interviewId = ""
                // this.interview.assignmentId = ""
                // this.$refs["interview-modal"].hide()
                this.getCandidates()
              })
              .catch((error) => {
                console.log(error)
                this.$refs["interview-modal"].hide()
                errorResponse(error, this.$router)
              })
          }
        })
    },
    async handleShare(data) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to Share to Client.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            axios
              .post("/share", {
                candidate_id: data.candidate_id,
                assignment_id: data.assignment_id,
              })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: `Assignment Share`,
                    icon: "CoffeeIcon",
                    variant: "success",
                    text: `You have successfully Shared the candidate!`,
                  },
                })
                this.getCandidates()
              })
              .catch((error) => {
                console.log(error)
                errorResponse(error, this.$router)
              })
          }
        })
    },
    handleInterview() {
      this.$refs.inviteCandidateForm.validate().then((success) => {
        if (success) {
          axios
            .post("/interview_candidate", this.interview)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: `Assignment Invites`,
                  icon: "CoffeeIcon",
                  variant: "success",
                  text: `You have successfully Invited the candidate!`,
                },
              })
              this.interview.updateDate = ""
              this.interview.updateStartTime = ""
              this.interview.addNote = ""
              this.interview.userName = ""
              this.interview.interviewId = ""
              this.interview.assignmentId = ""
              this.interview.title = ""
              this.interview.updateEndTime = ""
              this.interview.password = ""
              this.interview.interviewLink = ""
              this.getCandidates()
              this.$refs["interview-modal"].hide()

              this.$emit("detailed-getAssignment")
            })
            .catch((error) => {
              console.log(error)
              this.$refs["interview-modal"].hide()
              errorResponse(error, this.$router)
            })
        }
      })
    },
    routeToChat(data) {
      console.log(data)
      this.$router.push({
        name: `user-chat`,
        params: {
          candidate_assignments_id: data.id,
          assignment_id: data.assignment_id,
          name: data.user.name,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-slider.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.bor-1 {
  border-right: 1px solid lightgray;
}

.in__line {
  display: flex;
  flex-direction: row;
}

.y-center {
  display: flex;
  flex-direction: column;

  justify-content: center;
}

.title__box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.card__box {
  display: flex;
  justify-content: space-between;
}

.card__left {
  flex: 1;
}
.loc {
  font-size: 11px;
  padding-right: 5px;
}

.font-11 {
  font-size: 11px;
}

.bold {
  font-family: bold;
}
.inline {
  display: inline !important;
}

.name {
  margin-bottom: 0px;
}
.timeline-item {
  padding-bottom: 5px !important;
}

// .card-body {
//   padding: 0px !important;
// }
.experience_btn {
  cursor: pointer;
}
.location {
  display: inline !important;
}
.icon {
  margin-top: 5px;
}
.executive {
  margin-right: 5px;
  font-size: 12px;
}

.row_margin {
  margin-left: 0px;
  margin-right: 0px;
}
</style>

Row select support Selection mode: multi ID AVAT
