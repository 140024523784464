<template>
  <div>
    <job-title
      :assignments="assignmentData"
      @get-assignment-data-engagement="reloadPage"
    />
    <b-row>
      <b-col
        v-for="(assignment, i) in assignments"
        :key="i"
        lg="2"
        md="4"
        sm="6"
        @click="handleActiveAssignment(assignment.statisticTitle)"
      >
        <Assignment
          :icon="assignment.icon"
          :statistic="assignment.statistic"
          :statistic-title="assignment.statisticTitle"
          :color="assignment.color"
          :select-assignment="selectAssignment"
        />
      </b-col>
    </b-row>
    <b-row v-if="selectAssignment == 'Sourced'">
      <FilterCandidate
        :selected-type="selectAssignment"
        :assignments="assignmentData"
        @detailed-getAssignment="refreshAssignmentCandidates"
      />
    </b-row>
    <b-row>
      <b-col cols="12">
        <SelectedCandidate
          v-if="selectAssignment == 'Selected'"
          @detailed-getAssignment="refreshAssignmentCandidates"
        />
        <InterviewedCandidate
          v-if="selectAssignment == 'Interview'"
          :assignments="assignmentData"
          @detailed-getAssignment="refreshAssignmentCandidates"
        />
        <ShortlistedCandidate
          v-if="selectAssignment == 'Shortlisted'"
          :assignments="assignmentData"
          @detailed-getAssignment="refreshAssignmentCandidates"
        />
        <ArchivedCandidate
          v-if="selectAssignment == 'Archived'"
          :assignments="assignmentData"
          @detailed-getAssignment="refreshAssignmentCandidates"
        />
        <WorkingCandidate
          v-if="selectAssignment == 'Working'"
          :assignments="assignmentData"
          @detailed-getAssignment="refreshAssignmentCandidates"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BCol, BRow } from "bootstrap-vue"
import axios from "@/libs/axios"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

import Assignment from "../engagement/Assignment.vue"
import FilterCandidate from "./FilterCandidate.vue"
import JobTitle from "./JobTitle.vue"

import WorkingCandidate from "./WorkingCandidate.vue"
import SelectedCandidate from "./SelectedCandidate.vue"
import InterviewedCandidate from "./InterviewedCandidate.vue"
import ArchivedCandidate from "./ArchivedCandidate.vue"
import ShortlistedCandidate from "./ShortlistedCandidate.vue"
import errorResponse from "@/libs/axiosError"

export default {
  components: {
    JobTitle,
    BRow,
    BCol,
    Assignment,
    FilterCandidate,
    WorkingCandidate,
    SelectedCandidate,
    InterviewedCandidate,
    ArchivedCandidate,
    ShortlistedCandidate,
  },
  data: () => ({
    selectAssignment: "Shortlisted",
    assignments: [],
    assignmentData: [],
  }),
  mounted() {
    this.getAssignmentData()
    this.getAssignmentCandidateCounts()
  },
  methods: {
    reloadPage() {
      this.getAssignmentData()
    },
    refreshAssignmentCandidates() {
      this.getAssignmentData()
      this.getAssignmentCandidateCounts()
    },
    handleActiveAssignment(statisticTitle) {
      this.selectAssignment = statisticTitle
    },
    getAssignmentData() {
      axios
        .get(`/assignment/${this.$route.params.id}`)
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Assignment List`,
              icon: "CoffeeIcon",
              variant: "success",
              text: `You have successfully fetched Assignment List!`,
            },
          })

          this.assignmentData = res.data
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
    getAssignmentCandidateCounts() {
      axios
        .get(`/admin_candidate_assignment_count/${this.$route.params.id}`)
        .then((res) => {
          this.assignments = [
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.Sourced,
              statisticTitle: "Sourced",
              value: "Sourced",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.Shortlisted,
              statisticTitle: "Shortlisted",
              value: "Shortlisted",
            },

            {
              color: "primary",
              icon: "ServerIcon",
              statistic: res.data.Interview,
              statisticTitle: "Interview",
              value: "Interview",
            },

            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.Selected,
              statisticTitle: "Selected",
              value: "Selected",
            },

            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.Working,
              statisticTitle: "Working",
              value: "Working",
            },
            {
              color: "danger",
              icon: "ActivityIcon",
              statistic: res.data.Archived,
              statisticTitle: "Archived",
              value: "Archived",
            },
          ]
        })
        .catch((error) => {
          console.log(error)
          errorResponse(error, this.$router)
        })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
